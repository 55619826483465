import { useEffect, useRef } from "react"
import YouTube from "react-youtube"
import * as THREE from "three"
import { Canvas, useFrame } from "@react-three/fiber"
import {
  useGLTF,
  Stage,
  Grid,
  OrbitControls,
  Environment,
} from "@react-three/drei"
import { EffectComposer, Bloom, ToneMapping } from "@react-three/postprocessing"
import { easing } from "maath"

export default function App() {
  const playerRef = useRef(null)

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1, // Change this to 1
      loop: 1,
      playlist: "c8UK6T0iA80",
      vq: "hd1080",
    },
  }
  const onReady = (event) => {
    playerRef.current = event.target
    event.target.playVideo()
    event.target.setPlaybackQuality("hd1080")
    const { innerWidth: width, innerHeight: height } = window
    event.target.setSize(width, height)
  }

  const onPlaybackQualityChange = (event) => {
    const quality = event.target.getPlaybackQuality()
    if (quality !== "hd1080") {
      event.target.setPlaybackQuality("hd1080")
    }
  }
  return (
    <>
      <div className="relative sm:absolute sm:left-[5%] top-10 sm:top-[10%] z-50 sm:w-fit p-2 max-h-[90vh] sm:max-h-[90vh] overflow-y-auto scrollbar-hide">
        <div className="w-fit">
          <div className="px-2 sm:px-0 chakra-petch-bold">
            <div className="text-2xl sm:text-7xl  ">MOON SURVIVORS</div>
            <div className="text-xl sm:text-4xl z-60 sm:mt-2 ">
              Survive enemies waves
            </div>
          </div>
          <div className="mt-4 sm:mt-8 sm:w-[560px] ">
            <YouTube
              videoId="c8UK6T0iA80"
              opts={videoOptions}
              onReady={onReady}
              onPlaybackQualityChange={onPlaybackQualityChange}
              iframeClassName="youtube-iframe sm:w-[560px] sm:h-[320px] h-[250px] w-full"
            />
            <div className="bg-white/90 p-2 sm:p-4 font-bold chakra-petch-medium">
              <h2 className="text-xl sm:text-3xl chakra-petch-bold">
                MVP FEATURES DEVELOPED
              </h2>
              <div>
                ✅ Basic mechanics implemented: health system, enemies,
                wave-based progression, level-up, collisions, loots.
              </div>
              <div>
                ✅ Basic graphism implemented: lighting, environment,
                animations.
              </div>
              <h2 className="text-xl sm:text-3xl mt-2 sm:mt-4 chakra-petch-bold">
                DEMO
              </h2>
              <div>
                Shooting action{" "}
                <a
                  className="underline text-pink-500"
                  target="_blank"
                  href="https://youtu.be/c8UK6T0iA80?list=TLGG4c5nMa6VVgYyNjA2MjAyNA&t=7"
                >
                  Video 0:29
                </a>
              </div>
              <div>
                Loot system{" "}
                <a
                  className="underline text-pink-500"
                  target="_blank"
                  href=" https://youtu.be/c8UK6T0iA80?list=TLGG4c5nMa6VVgYyNjA2MjAyNA&t=54"
                >
                  Video 0:54
                </a>
              </div>
              <div>
                Heightfield collision & Explosion props{" "}
                <a
                  className="underline text-pink-500"
                  target="_blank"
                  href=" https://youtu.be/c8UK6T0iA80?list=TLGG4c5nMa6VVgYyNjA2MjAyNA&t=65"
                >
                  Video 1:05
                </a>
              </div>
              <div>
                Enemy throwing a grenade{" "}
                <a
                  className="underline text-pink-500"
                  target="_blank"
                  href=" https://youtu.be/c8UK6T0iA80?list=TLGG4c5nMa6VVgYyNjA2MjAyNA&t=68"
                >
                  Video 1:08
                </a>
              </div>
              <div>
                Height maps{" "}
                <a
                  className="underline text-pink-500"
                  target="_blank"
                  href="  https://youtu.be/c8UK6T0iA80?list=TLGG4c5nMa6VVgYyNjA2MjAyNA&t=87"
                >
                  Video 1:27
                </a>
              </div>

              <div>
                Player dash{" "}
                <a
                  className="underline text-pink-500"
                  target="_blank"
                  href="  https://youtu.be/c8UK6T0iA80?list=TLGG4c5nMa6VVgYyNjA2MjAyNA&t=109"
                >
                  Video 1:49
                </a>
              </div>
              <div>
                Vehicle experiments{" "}
                <a
                  className="underline text-pink-500"
                  target="_blank"
                  href="  https://youtu.be/c8UK6T0iA80?list=TLGG4c5nMa6VVgYyNjA2MjAyNA&t=114"
                >
                  Video 1:54
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Canvas flat shadows camera={{ position: [-15, 0, 10], fov: 25 }}>
        <fog attach="fog" args={["#ffffff", 20, 30]} />
        <directionalLight position={[5, 5, 5]} intensity={1} castShadow />
        <Stage
          intensity={0.5}
          environment="city"
          shadows={{ type: "accumulative", bias: -0.001, intensity: Math.PI }}
          adjustCamera={false}
        >
          <Kamdo rotation={[0, Math.PI, 0]} />
        </Stage>
        <Grid
          renderOrder={-1}
          position={[0, -1.85, 0]}
          infiniteGrid
          cellSize={0.6}
          cellThickness={0.6}
          sectionSize={3.3}
          sectionThickness={1.5}
          sectionColor={[0.8, 0.8, 1]} // Lighter blue
          cellColor={[0.9, 0.9, 1]} // Light blue for cells
          fadeDistance={30}
        />
        <OrbitControls
          autoRotate
          autoRotateSpeed={2.5}
          enableZoom={false}
          makeDefault
          minPolarAngle={Math.PI / 2}
          maxPolarAngle={Math.PI / 2}
        />
        <EffectComposer disableNormalPass>
          <Bloom luminanceThreshold={0.2} intensity={1.5} mipmapBlur />
          <ToneMapping />
        </EffectComposer>
      </Canvas>
    </>
  )
}

function Kamdo(props) {
  const group = useRef()
  const head = useRef()
  const stripe = useRef()
  const light = useRef()
  const { nodes, materials } = useGLTF(
    "/s2wt_kamdo_industrial_divinities-transformed.glb"
  )

  useEffect(() => {
    console.log("nodes", nodes)
  }, [nodes])

  useFrame((state, delta) => {
    const t = (1 + Math.sin(state.clock.elapsedTime * 2)) / 2
    stripe.current.color.setRGB(2 + t * 20, 2, 20 + t * 50)

    // Calculate the direction from the head to the camera
    const headWorldPosition = new THREE.Vector3()
    head.current.getWorldPosition(headWorldPosition)
    const cameraPosition = state.camera.position

    // Project both positions onto the XZ plane (y = 0)
    headWorldPosition.y = 0
    const cameraProjected = cameraPosition.clone().setY(0)

    const directionToCamera = new THREE.Vector3()
      .subVectors(cameraProjected, headWorldPosition)
      .normalize()

    // Calculate the model's world rotation around Y axis
    const modelQuaternion = new THREE.Quaternion()
    group.current.getWorldQuaternion(modelQuaternion)
    const modelEuler = new THREE.Euler().setFromQuaternion(modelQuaternion)

    // Calculate the angle between the model's forward direction and the direction to the camera
    const modelForward = new THREE.Vector3(0, 0, 1).applyEuler(modelEuler)
    const angleToCamera = modelForward.angleTo(directionToCamera)

    // Determine the sign of the angle
    const cross = new THREE.Vector3().crossVectors(
      modelForward,
      directionToCamera
    )
    const angleSign = Math.sign(cross.y)

    // Set the target rotation
    const targetAngle = angleToCamera * angleSign

    // Apply easing to the rotation
    easing.dampE(head.current.rotation, [0, targetAngle, 0], 0.4, delta)

    light.current.intensity = 1 + t * 4
  })

  return (
    <group {...props} ref={group}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.body001.geometry}
        material={materials.Body}
      />
      <group ref={head}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.head001.geometry}
          material={materials.Head}
        />
        <mesh castShadow receiveShadow geometry={nodes.stripe001.geometry}>
          <meshBasicMaterial ref={stripe} toneMapped={false} />
          <pointLight
            ref={light}
            intensity={1}
            color={[10, 2, 5]}
            distance={2.5}
          />
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload("/s2wt_kamdo_industrial_divinities-transformed.glb")
